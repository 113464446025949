@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply text-sm md:text-base lg:text-lg;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Play', sans-serif;
}

#title {
  font-family: 'Bungee', cursive;
}

ul {
  list-style-position: inside;
}

body {
  @apply bg-bg;
}

.connect-btn {
  @apply rounded-lg border-2 border-black my-1 px-6 py-1 focus:ring-0 text-black font-black cursor-pointer;
  box-shadow: 5px 5px 0px 0px #000000;
}

.connect-btn {
  @apply bg-btn-primary;
} .connect-btn:hover {
  @apply bg-btn-secondary text-black border-black;
}

.switch-btn {
  @apply rounded-lg border-2 border-black my-1 px-6 py-1 focus:ring-0 text-black font-black cursor-pointer;
  box-shadow: 5px 5px 0px 0px #000000;
}

.switch-btn {
  @apply bg-btn-primary;
} .switch-btn:hover {
  @apply text-black border-black;
  background-color: #45a8ff;
}

.connect-info {
  @apply rounded-lg border-2 border-black my-1 px-3 py-1 focus:ring-0 text-black font-black cursor-default;
}

.connect-info {
  @apply bg-btn-primary;
} .connect-btn:hover {
  @apply bg-btn-secondary text-black border-black;
}

.disconnect-btn {
  @apply rounded-lg border-2 border-black my-1 px-6 py-1 focus:ring-0 text-red-800 text-sm underline cursor-pointer;
  box-shadow: 5px 5px 0px 0px #000000;
}

.disconnect-btn {
  @apply bg-btn-primary;
} .disconnect-btn:hover {
  @apply bg-btn-secondary text-black border-black;
}

.btn-lt {
  @apply rounded-lg border-2 my-1 px-3 py-0.5 text-base focus:ring-0 text-black font-black cursor-pointer;
} .btn-lt:disabled {
  @apply text-gray-300 border-gray-300;
} .btn-lt:disabled:hover {
  @apply text-gray-300 border-gray-300 cursor-not-allowed;
}

.btn-lt-primary {
  @apply border-bg;
} .btn-lt-primary:not([disabled]):hover {
  @apply bg-bg border-bg text-black;
}

.btn-lt-secondary {
  @apply border-btn-primary;
} .btn-lt-secondary:not([disabled]):hover {
  @apply bg-btn-primary border-btn-primary text-black;
}

.btn-nav {
  @apply rounded-lg bg-btn-secondary border-black my-1 mx-2 px-6 py-1 focus:ring-0 text-black border-2 border-solid text-center font-black !important;
} .btn-nav.active {
  @apply bg-btn-primary !important;
  box-shadow: 5px 5px 0px 0px #000000 !important;
}

.btn-flex {
  @apply rounded-lg w-full border-gray-400 my-1 py-1 focus:ring-0 text-gray-400 border-2 border-solid text-center font-black !important;
} .btn-flex.active {
  @apply border-black bg-btn-secondary text-black !important;
}

.card {
  @apply border-2 border-black mx-4 mb-4 px-4 py-4 max-w-3xl rounded-lg shadow-md text-center;
  box-shadow: 5px 5px 0px 0px #000000;
}

.card-shadow {
  box-shadow: 5px 5px 0px 0px #000000;
}

.modal-content {
  @apply border-2 border-black rounded-lg text-center m-0 p-0 !important;
  box-shadow: 5px 5px 0px 0px #000000 !important;
}

.detail-width {
  width: 100%;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

/* Fix number inputs */
input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* unselectable text */
.unselectable {
   -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;

   /*
     Introduced in Internet Explorer 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
   -ms-user-select: none;
   user-select: none;
}